import {
  addAnimationClassOncePartiallyVisible,
  execAfterDomContentLoaded,
  unawaited,
} from "../../util";

let elMap: {
  iconImages: NodeListOf<HTMLElement>;
};

execAfterDomContentLoaded(afterContentLoaded);

async function afterContentLoaded() {
  elMap = {
    iconImages: document.querySelectorAll(".benefits-icon-img"),
  };

  unawaited(
    addAnimationClassOncePartiallyVisible(
      elMap.iconImages[0],
      "animate__bounceInLeft"
    )
  );
  unawaited(
    addAnimationClassOncePartiallyVisible(
      elMap.iconImages[1],
      "animate__bounceInRight"
    )
  );
}
